<template>
  <v-layout>
    <v-main>
      <Toolbar></Toolbar>
      <router-view :key="$route.fullPath"></router-view>
      <Footer></Footer>
    </v-main>
  </v-layout>
</template>
<script>
import Toolbar from '@/components/ui/landing/toolbar/ParfinitiToolbar'
import Footer from '@/components/ui/landing/footer/ParfinitiFooter'

export default {
  components: {
    Toolbar,
    Footer
  }
}
</script>
