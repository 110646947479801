export const menu1 = [{
  title: 'Solutions'
}, {
  title: 'Pricing'
}, {
  title: 'Docs'
}, {
  title: 'More'
}]

export const menu2 = [{
  title: 'Home',
  to: '/'
},{
  title: 'Services',
  to: '/services'
}, {
  title: 'About',
  to: '/about'
}, {
  title: 'Contact',
  to: '/contact'
}]

export default {
  menu1,
  menu2
}
